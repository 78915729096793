import { FieldInputProps, FieldProps, useField } from 'formik';

import {
  FieldWrapper,
  StyledErrorMessage,
  StyledField,
  TextInputContainer,
} from './styled';

export type FieldSize = 'lg' | 'md' | 'sm';

export interface TextFieldProps {
  name: string;
  size: FieldSize;

  placeholder?: string;
  type?: string;
  onIconClick?: () => void;

  paddingLeft?: string | number;
  paddingRight?: string | number;

  disabled?: boolean;

  errorMessage?: string;

  withoutFormik?: boolean;
  onChange?: (value: string) => void;

  defaultValue?: string;
  value?: string;
}

type FieldStyles = Record<FieldSize, { container: { height: number } }>;

export const textInputHeights: FieldStyles = {
  sm: { container: { height: 40 } },
  md: { container: { height: 48 } },
  lg: { container: { height: 56 } },
};

const TextInput = (props: TextFieldProps) => {
  let fieldProps: FieldInputProps<any> | undefined;

  if (!props.withoutFormik) {
    const [field, meta, helpers] = useField(props.name);
    fieldProps = field;
  }

  return (
    <TextInputContainer>
      <FieldWrapper>
        <StyledField
          {...fieldProps}
          disabled={props.disabled}
          onChange={(e) => {
            if (fieldProps) {
              fieldProps.onChange(e);
            } else if (props.onChange) {
              props.onChange(e.target.value);
            }
          }}
          defaultValue={props.defaultValue}
          data-testid={`field-${props.name}`}
          name={props.name}
          placeholder={props.placeholder}
          type={props.type ?? 'text'}
          isError={props.errorMessage ? true : false}
          style={{
            height: textInputHeights[props.size].container.height - 1,
            paddingLeft: props.paddingLeft ?? 30,
            paddingRight: props.paddingRight ?? 30,
          }}
        />
      </FieldWrapper>
      {props.errorMessage ? (
        <StyledErrorMessage>{props.errorMessage}</StyledErrorMessage>
      ) : null}
    </TextInputContainer>
  );
};

export default TextInput;
